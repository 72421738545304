/* eslint-disable no-console */
import { CookieScheme } from '~auth/runtime';

export default class phpSessionScheme extends CookieScheme {
  // Override `fetchUser` method of `local` scheme

  async fetchUser(endpoint) {
    // Try to fetch user and then set
    await this.$auth
      .requestWith(this.name, endpoint, this.options.endpoints.user)
      .then((response) => {
        const user = response.data[this.options.user.property];
        this.$auth.setUser(user);
        if (response.headers['set-cookie']) {
          this.$auth.ctx.res.setHeader(
            'Set-Cookie',
            response.headers['set-cookie']
          );
        }
        return response;
      })
      .catch((error) => {
        this.$auth.callOnError(error, { method: 'fetchUser' });
      });
  }
}
