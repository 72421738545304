export const state = () => ({
  countries: [],
  name: '',
  index_title: '',
  description: '',
  visible_profile_fields: [],
  geoip_country: null,
  geoip_selected_country: null,
  region_data: null,
  cdn_prefix: '',
  visible_profile_notes: null,
  message_credits: null,
  signup_credits: null,
  photo_credits: null,
  gift_credits: null,
  message_length: null,
  minimum_message_costs: null,
  support_email: null,
  company_name: null,
  company_address: null,
  company_kvk: null,
  company_city: null,
  company_country: null,
  company_tradenames: null,
  company_vat_numbers: null,
  delete_discount: null,
  enable_site_entry_popup: null,
  push_public_key: null,
  search_fields: [],
  domain: null,
  domain_language: null,
  fictional_warning: null,
  fictional_singular: null,
  fictional_plural: null,
  refund_days: null,
  signups: null,
  logged_out_search: null,
  logged_out_search_trafficsource: null,
  support_phone: null,
  interests: null,
  member_resell: [],
});

export const mutations = {
  set_countries(state, countries) {
    state.countries = countries;
  },
  set_settings(state, settings) {
    state.countries = settings.countries;
    state.name = settings.name;
    state.index_title = settings.index_title;
    state.description = settings.description;
    state.visible_profile_fields = settings.visible_profile_fields;
    state.geoip_country = settings.geoip_country;
    state.geoip_selected_country = settings.geoip_selected_country;
    state.cdn_prefix = settings.cdn_prefix;
    state.visible_profile_notes = settings.visible_profile_notes;
    state.message_credits = settings.message_credits;
    state.photo_credits = settings.photo_credits;
    state.gift_credits = settings.gift_credits;
    state.message_length = settings.message_length;
    state.minimum_message_costs = settings.minimum_message_costs;
    state.support_email = settings.support_email;
    state.enable_site_entry_popup = settings.enable_site_entry_popup;
    state.push_public_key = settings.push_pub;
    state.search_fields = settings.search_fields;
    state.signup_credits = settings.signup_credits;
    state.domain = settings.domain;
    state.domain_language = settings.domain_language;
    state.fictional_warning = settings.fictional_warning;
    state.fictional_plural = settings.fictional_plural;
    state.fictional_singular = settings.fictional_singular;
    state.refund_days = settings.refund_days;
    state.signups = settings.signups;
    state.logged_out_search = settings.logged_out_search;
    state.logged_out_search_trafficsource =
      settings.logged_out_search_trafficsource;
    state.member_resell = settings.member_resell;
  },
  set_region_data(state, rd) {
    state.region_data = rd;
  },
  set_interests_data(state, rd) {
    state.interests = rd;
  },
  set_company_info(state, rd) {
    state.company_name = rd.name;
    state.company_address = rd.address;
    state.company_kvk = rd.kvk;
    state.company_city = rd.city;
    state.company_country = rd.country;
    state.company_tradenames = rd.tradenames;
    state.company_vat_numbers = rd.vat_numbers;
    state.support_phone = rd.support_phone;
    state.delete_discount = rd.delete_discount;
  },
};

export const actions = {
  load_interests({ commit, state }, type) {
    if (state.interests) {
      return 1;
    }
    return this.$axios.get('/api/interests.php').then((res) => {
      commit('set_interests_data', res.data);
    });
  },

  load_region_data({ commit, state }, type) {
    if (state.region_data) {
      return 1;
    }
    return this.$axios.get('/api/regions.php').then((res) => {
      commit('set_region_data', res.data);
    });
  },
  load_company_data({ commit, state }, type) {
    if (state.company_name) {
      return 1;
    }
    return this.$axios.get('/api/companyinfo.php').then((res) => {
      commit('set_company_info', res.data);
    });
  },
};

export const getters = {
  getSupportPhoneClean: (state) => {
    if (state.support_phone === null) {
      return null;
    }
    return state.support_phone.replace(/[-\s]/g, '');
  },
};
