var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{staticClass:"topnav default-topnav",attrs:{"sticky":""}},[(_vm.showBackButton)?_c('b-navbar-nav',[_c('b-nav-item',{staticClass:"back-btn",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('font-awesome-icon',{attrs:{"size":"lg","icon":['far', 'chevron-left']}}),_vm._v("\n      "+_vm._s(_vm.$t('Back'))+"\n    ")],1)],1):_vm._e(),_vm._v(" "),_c('b-navbar-brand',{class:{
      'ml-auto ': _vm.showBackButton,
    },attrs:{"to":_vm.$auth.loggedIn
        ? _vm.localePath({
            name: 'members',
          })
        : _vm.localePath({
            name: 'index',
          })}},[_c('logo',{staticClass:"logo-default"})],1),_vm._v(" "),(_vm.$auth.loggedIn)?_c('b-navbar-nav',{staticClass:"top-menu-items"},[_c('b-nav-item',{staticClass:"mr-xl-2",attrs:{"to":_vm.localePath({ name: 'members' })}},[_c('font-awesome-icon',{staticClass:"mr-1 topnavicon",attrs:{"icon":_vm.designVersion === 2 ? ['far', 'magnifying-glass'] : ['far', 'user']}}),_vm._v(" "),(_vm.$te('Search members - short'))?_c('span',{staticClass:"small-text"},[_vm._v(_vm._s(_vm.$t('Search members - short')))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"full-text"},[_vm._v(_vm._s(_vm.$t('Search members')))])],1),_vm._v(" "),_c('b-nav-item',{staticClass:"mr-xl-2",class:{ 'd-lg-none': _vm.designVersion === 2 },attrs:{"to":_vm.localePath({ name: 'chats' })}},[_c('font-awesome-icon',{staticClass:"mr-1 topnavicon",attrs:{"icon":['far', 'comments']}}),_vm._v(" "),(_vm.$te('Messages - short'))?_c('span',{staticClass:"small-text"},[_vm._v(_vm._s(_vm.$t('Messages - short')))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"full-text"},[_vm._v(_vm._s(_vm.$t('Messages')))]),_vm._v(" "),_c('client-only',[(_vm.$store.getters['chat/getTotalUnreadMessages'])?_c('b-badge',{attrs:{"pill":"","variant":"unread-messages"}},[(_vm.$store.getters['chat/getTotalUnreadMessages'] < 100)?[_vm._v("\n            "+_vm._s(_vm.$store.getters['chat/getTotalUnreadMessages'])+" ")]:[_vm._v("99+")]],2):_vm._e()],1)],1),_vm._v(" "),(_vm.designVersion == 2)?_c('b-nav-item',{staticClass:"mr-xl-2 d-none d-lg-block",attrs:{"to":_vm.$store.getters['chat/getTotalMessages'] > 0
          ? _vm.localePath({
              name: 'chats-id',
              params: {
                id: _vm.$store.getters['chat/getMessages'][0].conversation.id,
              },
            })
          : _vm.localePath({ name: 'chats' })}},[_c('font-awesome-icon',{staticClass:"mr-1 topnavicon",attrs:{"icon":['far', 'comments']}}),_vm._v(" "),(_vm.$te('Messages - short'))?_c('span',{staticClass:"small-text"},[_vm._v(_vm._s(_vm.$t('Messages - short'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"full-text"},[_vm._v(_vm._s(_vm.$t('Messages')))]),_vm._v(" "),_c('client-only',[(_vm.$store.getters['chat/getTotalUnreadMessages'])?_c('b-badge',{attrs:{"pill":"","variant":"unread-messages"}},[(_vm.$store.getters['chat/getTotalUnreadMessages'] < 100)?[_vm._v("\n            "+_vm._s(_vm.$store.getters['chat/getTotalUnreadMessages']))]:[_vm._v("99+")]],2):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('b-nav-item',{staticClass:"mr-xl-2",attrs:{"to":_vm.localePath({ name: 'likes' })}},[_c('font-awesome-icon',{staticClass:"mr-1 topnavicon",attrs:{"icon":['far', 'heart']}}),_vm._v(" "),(_vm.$te('Likes - short'))?_c('span',{staticClass:"small-text"},[_vm._v(_vm._s(_vm.$t('Likes - short')))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"full-text"},[_vm._v(_vm._s(_vm.$t('Likes')))]),_vm._v(" "),_c('client-only',[(_vm.$store.getters['chat/getTotalNewLikes'])?_c('b-badge',{attrs:{"pill":"","variant":"unread-messages"}},[(_vm.$store.getters['chat/getTotalNewLikes'] < 100)?[_vm._v("\n            "+_vm._s(_vm.$store.getters['chat/getTotalNewLikes'])+" ")]:[_vm._v("99+")]],2):_vm._e()],1)],1),_vm._v(" "),(!_vm.$auth.user.paid_user)?_c('b-nav-item',{attrs:{"to":_vm.localePath({ name: 'premium' }),"link-classes":_vm.$route.name === 'credits' ||
        _vm.$route.name === 'credits-id-paymentmethod'
          ? 'nuxt-link-active'
          : ''}},[_c('font-awesome-icon',{staticClass:"mr-1 topnavicon",attrs:{"icon":['far', 'gem']}}),_vm._v(" "),(_vm.$te('Get Premium - short'))?_c('span',{staticClass:"small-text"},[_vm._v(_vm._s(_vm.$t('Get Premium - short')))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"full-text"},[_vm._v(_vm._s(_vm.$t('Get Premium')))])],1):_c('b-nav-item',{attrs:{"link-classes":_vm.$route.name === 'credits' ||
        _vm.$route.name === 'credits-id-paymentmethod'
          ? 'nuxt-link-active'
          : '',"to":_vm.localePath({ name: 'credits-repeat-order' })}},[_c('font-awesome-icon',{staticClass:"mr-1",class:_vm.designVersion === 2
            ? 'creditsiconcard topnavicon'
            : 'creditsicongem',attrs:{"icon":_vm.designVersion === 2 ? ['far', 'credit-card'] : ['far', 'gem']}}),_vm._v(" "),(_vm.$te('Credits - short'))?_c('span',{staticClass:"small-text"},[_vm._v(_vm._s(_vm.$t('Credits - short')))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"full-text"},[_vm._v(_vm._s(_vm.$t('Credits')))]),_vm._v(" "),(_vm.$auth.user.credits <= 10)?_c('b-badge',{staticClass:"credits",attrs:{"pill":"","variant":_vm.$auth.user.credits > 0 ? 'warning' : 'danger'}},[_vm._v("\n        "+_vm._s(_vm.$auth.user.credits)+"\n      ")]):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.$route.name !== 'complete-signup')?_c('b-navbar-nav',{staticClass:"nav-dropdowns"},[_c('b-button-group',[(_vm.$i18n.locales.length > 1 && !_vm.hideHeaderLocaleSwitcher)?_c('b-dropdown',{staticClass:"locale-dropdown",attrs:{"right":"","variant":"locale-dropdown"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"align-middle"},[_c('font-awesome-icon',{staticClass:"globe",attrs:{"icon":['far', 'globe']}}),_vm._v(" "),_c('span',{staticClass:"languagetext d-none d-xl-inline"},[_vm._v("\n              "+_vm._s(_vm.languageName(_vm.$i18n.localeProperties, _vm.$i18n.locale))+"\n            ")])],1)]},proxy:true}],null,false,1401789804)},[_vm._v(" "),_c('client-only',_vm._l((_vm.$i18n.locales),function(loc){return _c('b-dropdown-item',{key:loc.code,on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.changeLocale(loc.code)}}},[_vm._v(_vm._s(_vm.languageName(loc, loc.code))+"\n            "),(
                _vm.languageName(loc, loc.code) !== _vm.languageName(loc, undefined)
              )?[_vm._v("\n              / "+_vm._s(_vm.languageName(loc, undefined))+"\n            ")]:_vm._e(),_vm._v(" "),(
                _vm.languageName(loc, _vm.$i18n.locale) !==
                  _vm.languageName(loc, undefined) &&
                _vm.languageName(loc, _vm.$i18n.locale) !==
                  _vm.languageName(loc, loc.code)
              )?[_vm._v("\n              / "+_vm._s(_vm.languageName(loc, _vm.$i18n.locale))+"\n            ")]:_vm._e()],2)}),1)],1):_vm._e(),_vm._v(" "),(!_vm.$auth.loggedIn)?_c('b-button',{staticClass:"my-0 align-middle",attrs:{"to":_vm.localePath({ name: 'members' }),"size":"m","type":"submit","variant":"topmenu"}},[_vm._v("\n        "+_vm._s(_vm.$t('Login'))+"\n      ")]):_c('b-dropdown',{ref:"myaccountdropdown",staticClass:"rounded text-center myaccountdropdown",attrs:{"right":"","variant":"dropdown"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.$auth.user.profile.main_image)?_c('figure',{staticClass:"accountimage"},[_c('img',{attrs:{"src":_vm.$store.state.settings.cdn_prefix +
                _vm.$auth.user.profile.main_image.thumb_filename}})]):_c('font-awesome-icon',{staticClass:"mr-1 accounticon",attrs:{"icon":['far', 'user']}}),_vm._v(" "),_c('em',{staticClass:"profilename",class:{ 'mx-lg-3': _vm.$auth.user.profile.main_image }},[_vm._v("\n            "+_vm._s(_vm.$auth.user.profile.name)+"\n          ")])]},proxy:true}],null,false,23030719)},[_vm._v(" "),_c('b-dropdown-item',{attrs:{"to":_vm.localePath({ name: 'profile' })}},[_vm._v("\n          "+_vm._s(_vm.$t('My profile')))]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"to":_vm.localePath({ name: 'notifications' })}},[_vm._v("\n          "+_vm._s(_vm.$t('Settings')))]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$auth.logout()}}},[_vm._v("\n          "+_vm._s(_vm.$t('Logout')))])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }