import axiosRetry from 'axios-retry';

export default function ({ $axios, app, redirect }) {
  axiosRetry($axios, {
    retries: 5,
    // eslint-disable-next-line import/no-named-as-default-member
    retryDelay: axiosRetry.exponentialDelay,
  });

  $axios.onRequest((config) => {
    if (config.url.endsWith('user.php')) {
      config.headers['X-Request-Type'] = process.server ? 'server' : 'client';
    }
  });
  $axios.onError((error) => {
    const code = parseInt(error.response?.status);
    if (code === 403) {
      if (
        error.request &&
        !error.request.responseURL?.endsWith('user.php') &&
        error.response.data.authentication_required
      ) {
        if (app.$auth.loggedIn) {
          app.$auth
            .logout()
            .then((res) => {})
            .catch(() => {});
        }
        return Promise.resolve(false);
      }
    }
  });
}
