
export default {
  name: 'Default',
  data() {
    return {
      fetchUserInterval: null,
    };
  },
  head() {
    return {
      title: this.$store.state.settings.index_title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.settings.description,
        },
      ],
    };
  },
  mounted() {
    this.detectPushCapabilities();

    this.storeTrackingQuery();

    if (this.$auth.loggedIn) {
      this.$store.dispatch('chat/load_chats');
      this.$store.dispatch('chat/load_likes');
    }
  },
  beforeDestroy() {
    if (this.fetchUserInterval) {
      clearInterval(this.fetchUserInterval);
    }
  },
  methods: {},
};
