
export default {
  props: {
    profile: {
      type: Object,
      required: false,
      default: null,
    },
    cdnPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      renderProfile: null,
      isStartingConversation: false,
    };
  },
  async fetch() {
    if (this.profile) {
      this.renderProfile = this.profile;
    } else {
      const [profileData] = await Promise.all([
        this.$axios.get('/api/auto-like.php', {}),
      ]);
      this.renderProfile = profileData.data;
      setTimeout(() => {
        this.$store.dispatch('chat/load_likes');
      }, 500);
    }
  },
  methods: {
    startConversation() {
      this.isStartingConversation = true;
      this.$emit('startConversation');
    },
  },
};
