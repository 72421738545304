
export default {
  name: 'ChatLayout',
  data() {
    return {
      fetchUserInterval: null,
    };
  },
  head() {
    return {
      htmlAttrs: {
        id: 'chatlayout',
      },
      meta: [
        {
          name: 'viewport',
          content:
            'width=device-width,height=device-height,initial-scale=1,shrink-to-fit=no',
        },
      ],
    };
  },
  beforeDestroy() {
    if (this.fetchUserInterval) {
      clearInterval(this.fetchUserInterval);
    }
  },
  mounted() {
    this.detectPushCapabilities();

    setTimeout(() => {
      if (!this.fetchUserInterval) {
        this.storeTrackingQuery();

        // use a higher timeout when push is enabled
        const refreshTimout = this.$store.state.push.isSubscribed
          ? 300000
          : 60000;

        this.fetchUserInterval = setInterval(() => {
          if (this.$auth.loggedIn) {
            this.$auth.fetchUser();
            this.$store.dispatch('chat/load_chats');
            this.$store.dispatch('chat/load_likes');
          }
        }, refreshTimout);
      }
    }, 5000);
    if (this.$auth.loggedIn && !this.$store.state.chat.chats) {
      this.$store.dispatch('chat/load_chats');
      this.$store.dispatch('chat/load_likes');
    }
  },
};
