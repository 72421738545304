import Vue from 'vue';

import {
  ModalPlugin,
  CardPlugin,
  DropdownPlugin,
  BVModalPlugin,
  BVToastPlugin,
  AlertPlugin,
  BadgePlugin,
  FormPlugin,
  NavbarPlugin,
  FormTextareaPlugin,
  FormCheckboxPlugin,
  PaginationPlugin,
  TabsPlugin,
  ProgressPlugin,
  ButtonGroupPlugin,
  BFormGroup,
  BFormRadio,
  BFormSelect,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupText
} from 'bootstrap-vue';

Vue.use(ModalPlugin);
Vue.use(CardPlugin);
Vue.use(DropdownPlugin);
Vue.use(BVModalPlugin);
Vue.use(BVToastPlugin);
Vue.use(AlertPlugin);
Vue.use(BadgePlugin);
Vue.use(FormPlugin);
Vue.use(NavbarPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(PaginationPlugin);
Vue.use(TabsPlugin);
Vue.use(ProgressPlugin);
Vue.use(ButtonGroupPlugin);

Vue.component('BFormGroup', BFormGroup);
Vue.component('BFormRadio', BFormRadio);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BButton', BButton);
Vue.component('BFormInput', BFormInput);
Vue.component('BInputGroup', BInputGroup);
Vue.component('BInputGroupText', BInputGroupText);
