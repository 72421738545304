export const state = () => ({
  last_chat_opened_profile: null,
  last_chat_opened_date: null,
  chat_messages: {},
  chats: null,
  likes: null,
  liked_by: null,
});

const updateAppBadge = function (state) {
  if (typeof navigator !== 'undefined' && navigator.setAppBadge) {
    const chatCnt =
      state.chats !== null
        ? state.chats.reduce((a, b) => a + b.num_unread_messages, 0)
        : 0;

    const likeCnt =
      state.liked_by !== null
        ? state.liked_by.filter((x) => x.like_new).length
        : 0;

    navigator.setAppBadge(chatCnt + likeCnt).catch(() => {});
  }
};

export const mutations = {
  set_last_chat_opened_profile(state, openedProfile) {
    state.last_chat_opened_profile = openedProfile;
    state.last_chat_opened_date = new Date();
  },

  set_chat_message(state, dict) {
    state.chat_messages[dict.conversation] = dict.msg;
  },

  set_chats(state, dict) {
    state.chats = dict.chats;
    updateAppBadge(state);
  },
  set_likes(state, dict) {
    state.likes = dict.profiles;
    state.liked_by = dict.liked_by || [];
    updateAppBadge(state);
  },
  remove_chat_with_conversation_id(state, conversationId) {
    state.chats = state.chats?.filter(
      (x) => x.conversation.id !== conversationId
    );
    this.$axios
      .delete('/api/chat.php', {
        data: {
          conversation: conversationId,
        },
      })
      .then((res) => {
        updateAppBadge(state);
      })
      .catch((error) => {
        // handle error
        alert(error);
        this.$store.dispatch('chat/load_chats');
      });
  },
  remove_chat_with_profile_id(state, profileId) {
    const chats = state.chats?.filter((x) => x.profile.id === profileId);
    if (chats) {
      for (const c of chats) {
        this.commit('chat/remove_chat_with_conversation_id', c.conversation.id);
      }
    }
  },
  open_chat_with_conversation_id(state, conversationId) {
    if (state.chats === null) {
      return;
    }
    state.chats = state.chats?.map((x) => {
      x.num_unread_messages =
        x.conversation.id === conversationId ? 0 : x.num_unread_messages;
      return x;
    });
    updateAppBadge(state);
  },
  open_profile_id(state, profileId) {
    if (state.liked_by === null) {
      return;
    }
    state.liked_by = state.liked_by?.map((x) => {
      x.like_new = x.id === profileId ? false : x.like_new;
      return x;
    });
    updateAppBadge(state);
  },
};

export const actions = {
  load_chats({ commit, state }, type) {
    return this.$axios
      .get('/api/chats.php', {
        progress: state.chats === null,
      })
      .then((res) => {
        if (res) {
          commit('set_chats', res.data);
        }
      });
  },
  load_likes({ commit, state }, type) {
    return this.$axios
      .get('/api/likes.php', {
        progress: state.likes === null,
      })
      .then((res) => {
        if (res) {
          commit('set_likes', res.data);
        }
      });
  },
};
export const getters = {
  getOrderedReceivedLikes: (state) => {
    const receivedLikes = state.liked_by;
    const oldReceivedLikes = receivedLikes?.filter((x) => x.like_new !== true);
    const newReceivedLikes = receivedLikes?.filter((x) => x.like_new === true);
    return newReceivedLikes?.concat(oldReceivedLikes);
  },
  getTotalNewLikes: (state) => {
    if (state.liked_by === null) {
      return null;
    }
    return state.liked_by.filter((x) => x.like_new).length;
  },

  getMessages: (state) => {
    if (state.chats === null) {
      return [];
    }
    return state.chats;
  },

  getTotalMessages: (state) => {
    if (state.chats === null) {
      return 0;
    }
    return state.chats.length;
  },

  getTotalUnreadMessages: (state) => {
    if (state.chats === null) {
      return null;
    }
    return state.chats.reduce((a, b) => a + b.num_unread_messages, 0);
  },

  getTotalUnreadMessagesExcludingConversation:
    (state) => (conversationIdToExclude) => {
      if (state.chats === null) {
        return null;
      }
      return state.chats
        .filter((x) => x.conversation.id !== conversationIdToExclude)
        .reduce((a, b) => a + b.num_unread_messages, 0);
    },
};
