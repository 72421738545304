
export default {
  props: {
    onlyForCountries: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      needsWarning: false,
      isPWA: false,
    };
  },
  computed: {
    showWarning() {
      return (
        this.needsWarning &&
        this.$store.state.signupFlowEmbeddedSiteEntryWarning !== true
      );
    },
  },
  watch: {
    showWarning(val) {
      if (val) {
        this.$forceNextTick(() => {
          this.$bvModal.show('site-entry-warning');
        });
      }
    },
  },
  mounted() {
    this.isPWA = window.matchMedia('(display-mode: standalone)')?.matches;

    if (
      !this.isPWA &&
      !this.$auth.$storage.getUniversal('skipped-site-entry-warning') &&
      this.$store.state.settings.enable_site_entry_popup !== 'no' &&
      !this.$auth.loggedIn &&
      (this.onlyForCountries === null ||
        this.onlyForCountries.includes(
          this.$store.state.settings.geoip_selected_country
        ))
    ) {
      this.needsWarning = true;
    }
  },
  methods: {
    didCloseModal() {
      this.$auth.$storage.setUniversal(
        'skipped-site-entry-warning',
        this.$dayjs().unix()
      );
    },
    closeModal() {
      this.$bvModal.hide('site-entry-warning');
    },
  },
};
