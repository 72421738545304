
export default {
  name: 'Default',
  data() {
    return {
      fetchUserInterval: null,
      signupInProgress: false,
    };
  },
  head() {
    return {
      title: this.$store.state.settings.index_title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.settings.description,
        },
      ],
    };
  },
  mounted() {
    this.detectPushCapabilities();

    if (this.$route.query.lb) {
      this.enablePageBounceLogging();
    }

    if (!this.fetchUserInterval) {
      this.storeTrackingQuery();
      this.$forceNextTick(() => {
        if (this.$auth.loggedIn && !this.$store.state.chat.chats) {
          this.$store.dispatch('chat/load_chats');
        }
      });
    }
  },
  beforeDestroy() {
    if (this.fetchUserInterval) {
      clearInterval(this.fetchUserInterval);
    }
  },
};
