import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faThumbsUp as fortawesomeproregularsvgicons_faThumbsUp } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faThumbsUp)

    import  { faCommentAlt as fortawesomeproregularsvgicons_faCommentAlt } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faCommentAlt)

    import  { faComments as fortawesomeproregularsvgicons_faComments } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faComments)

    import  { faHeart as fortawesomeproregularsvgicons_faHeart } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faHeart)

    import  { faLips as fortawesomeproregularsvgicons_faLips } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faLips)

    import  { faPlus as fortawesomeproregularsvgicons_faPlus } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faPlus)

    import  { faTimes as fortawesomeproregularsvgicons_faTimes } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faTimes)

    import  { faUser as fortawesomeproregularsvgicons_faUser } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faUser)

    import  { faChevronLeft as fortawesomeproregularsvgicons_faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faChevronLeft)

    import  { faChevronRight as fortawesomeproregularsvgicons_faChevronRight } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faChevronRight)

    import  { faChevronUp as fortawesomeproregularsvgicons_faChevronUp } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faChevronUp)

    import  { faChevronDown as fortawesomeproregularsvgicons_faChevronDown } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faChevronDown)

    import  { faCheck as fortawesomeproregularsvgicons_faCheck } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faCheck)

    import  { faMask as fortawesomeproregularsvgicons_faMask } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faMask)

    import  { faCreditCard as fortawesomeproregularsvgicons_faCreditCard } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faCreditCard)

    import  { faMobileAlt as fortawesomeproregularsvgicons_faMobileAlt } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faMobileAlt)

    import  { faSignInAlt as fortawesomeproregularsvgicons_faSignInAlt } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faSignInAlt)

    import  { faSpinner as fortawesomeproregularsvgicons_faSpinner } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faSpinner)

    import  { faGlobe as fortawesomeproregularsvgicons_faGlobe } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faGlobe)

    import  { faGem as fortawesomeproregularsvgicons_faGem } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faGem)

    import  { faEnvelopeOpen as fortawesomeproregularsvgicons_faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faEnvelopeOpen)

    import  { faPaperPlane as fortawesomeproregularsvgicons_faPaperPlane } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faPaperPlane)

    import  { faImages as fortawesomeproregularsvgicons_faImages } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faImages)

    import  { faForward as fortawesomeproregularsvgicons_faForward } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faForward)

    import  { faBackward as fortawesomeproregularsvgicons_faBackward } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faBackward)

    import  { faBullhorn as fortawesomeproregularsvgicons_faBullhorn } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faBullhorn)

    import  { faCamera as fortawesomeproregularsvgicons_faCamera } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faCamera)

    import  { faGift as fortawesomeproregularsvgicons_faGift } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faGift)

    import  { faEllipsisV as fortawesomeproregularsvgicons_faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faEllipsisV)

    import  { faMapMarkerSmile as fortawesomeproregularsvgicons_faMapMarkerSmile } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faMapMarkerSmile)

    import  { faMapMarker as fortawesomeproregularsvgicons_faMapMarker } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faMapMarker)

    import  { faLock as fortawesomeproregularsvgicons_faLock } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faLock)

    import  { faImage as fortawesomeproregularsvgicons_faImage } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faImage)

    import  { faBan as fortawesomeproregularsvgicons_faBan } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faBan)

    import  { faShieldSlash as fortawesomeproregularsvgicons_faShieldSlash } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faShieldSlash)

    import  { faUserSecret as fortawesomeproregularsvgicons_faUserSecret } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faUserSecret)

    import  { faEnvelope as fortawesomeproregularsvgicons_faEnvelope } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faEnvelope)

    import  { faEnvelopeDot as fortawesomeproregularsvgicons_faEnvelopeDot } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faEnvelopeDot)

    import  { faTrashXmark as fortawesomeproregularsvgicons_faTrashXmark } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faTrashXmark)

    import  { faBell as fortawesomeproregularsvgicons_faBell } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faBell)

    import  { faAt as fortawesomeproregularsvgicons_faAt } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faAt)

    import  { faCirclePlus as fortawesomeproregularsvgicons_faCirclePlus } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faCirclePlus)

    import  { faCircleXmark as fortawesomeproregularsvgicons_faCircleXmark } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faCircleXmark)

    import  { faMagnifyingGlass as fortawesomeproregularsvgicons_faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faMagnifyingGlass)

    import  { faLocationArrow as fortawesomeproregularsvgicons_faLocationArrow } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faLocationArrow)

    import  { faCoins as fortawesomeproregularsvgicons_faCoins } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faCoins)

    import  { faFaceSmile as fortawesomeproregularsvgicons_faFaceSmile } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faFaceSmile)

    import  { faFire as fortawesomeproregularsvgicons_faFire } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faFire)

    import  { faStar as fortawesomeproregularsvgicons_faStar } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faStar)

    import  { faCameraWeb as fortawesomeproregularsvgicons_faCameraWeb } from '@fortawesome/pro-regular-svg-icons'
    library.add(fortawesomeproregularsvgicons_faCameraWeb)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
