// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/lander.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navlander{background:#f7f8fb;border-bottom:0}.navlander figure{border-radius:50%;display:inline-block;height:30px;margin:0;overflow:hidden;vertical-align:middle;width:30px}.navlander figure img{width:100%}.navlander em{margin-top:-15px}.bg-lander{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0 0,top;background-position:-480px;background-repeat:repeat-x,no-repeat;margin-top:-10px}@media (max-width:1199.98px){.bg-lander{background-position:-650px;background-repeat:repeat-x,no-repeat}}@media (max-width:991.98px){.bg-lander{background-position:-800px;background-repeat:repeat-x,no-repeat}}@media (max-width:767.98px){.bg-lander{background-position:-900px;background-repeat:repeat-x,no-repeat}}@media (max-width:575.98px){.bg-lander{background-position:-1000px;background-repeat:repeat-x,no-repeat}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
