import Vue from 'vue';
import VueSimpleContextMenu from 'vue-simple-context-menu';
Vue.component('VueSimpleContextMenu', VueSimpleContextMenu);

const mixins = {
  data() {
    return {
      isMounted: false,
    };
  },
  methods: {
    landerSlugWithLocale(slug) {
      const localeToFileMap = {
        en: 'eng',
      };

      const autoFile = localeToFileMap[this.$i18n.locale] || this.$i18n.locale;
      if (slug === '' || slug === undefined) {
        return autoFile;
      }

      return slug.replace('auto', autoFile);
    },
    async detectGeolocationPermissions() {
      if (!navigator.geolocation) {
        this.$store.dispatch('setGeolocationPermission', 'unsupported');
        return 'unsupported';
      }
      if (navigator.permissions && navigator.permissions.query) {
        const result = await navigator.permissions.query({
          name: 'geolocation',
        });
        this.$store.dispatch('setGeolocationPermission', result.state);
        result.addEventListener('change', () => {
          this.$store.dispatch('setGeolocationPermission', result.state);
        });
        return result.state;
      } else {
        this.$store.dispatch('setGeolocationPermission', 'unknown');
        return 'unknown';
      }
    },
    getGeolocationAndReverseGeocode() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.$axios
              .get('/api/geocode.php', {
                params: {
                  lat: pos.coords.latitude,
                  lon: pos.coords.longitude,
                },
              })
              .then((res) => {
                this.$store.dispatch('setGeolocationGeocode', res.data);
              })
              .catch(() => {
                this.$store.dispatch('setGeolocationGeocode', false);
              });
          },
          null,
          {
            maximumAge: 1000 * 60 * 60,
          }
        );
      }
    },
    profileHead() {
      if (this.profile) {
        const meta = [
          {
            hid: 'og:type',
            name: 'og:type',
            content: 'website',
          },
          {
            hid: 'og:url',
            name: 'og:url',
            content:
              'https://' + this.$store.state.settings.domain + this.$route.path,
          },
        ];

        if (
          this.profile.main_image &&
          this.profile.main_image.filename &&
          this.profile.main_image.status === 'active'
        ) {
          meta.push({
            hid: 'og:image',
            name: 'og:image',
            content:
              this.$store.state.settings.cdn_prefix +
              this.profile.main_image.filename,
          });
        }

        return {
          title: this.profile.name + ' | ' + this.$store.state.settings.name,
          meta,
        };
      }
    },
    enablePageBounceLogging() {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          const data = {};
          const blob = new Blob([JSON.stringify(data)], {
            type: 'application/json',
          });

          if (navigator.sendBeacon) {
            navigator.sendBeacon('/api/page-hidden.php', blob);
          }
        }
      });
    },

    urlB64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        // eslint-disable-next-line no-useless-escape
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    addPushSubscriptionToAccount() {
      if (this.hasPushSubscription) {
        this.$axios.post('/api/user.php?push=1', {
          subscription: this.$store.state.push.isSubscribed,
        });
      }
    },
    async subscribePush() {
      const registration = await navigator.serviceWorker.register(
        '/sw-push.js?v=2'
      );
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: this.urlB64ToUint8Array(
          this.$store.state.settings.push_public_key
        ),
      };
      try {
        const pushSubscription = await registration.pushManager.subscribe(
          subscribeOptions
        );
        const jsonString = JSON.stringify(pushSubscription);
        this.$store.dispatch('setPushSubscribed', jsonString);

        this.addPushSubscriptionToAccount();
        return pushSubscription;
      } catch (e) {
        // err\
        if (window.Notification.permission === 'denied') {
          this.$store.dispatch('setPushSubscribed', 'denied');
        }
      }
    },
    async detectPushCapabilities(e) {
      const isCapable = 'serviceWorker' in navigator && 'PushManager' in window;
      this.$store.dispatch('setPushCapable', isCapable);

      if (isCapable) {
        try {
          const registration = await navigator.serviceWorker.register(
            '/sw-push.js?v=2'
          );
          navigator.serviceWorker.onmessage = (event) => {
            // store message to vuex; so components can watch for changes to reload
            if (event.data.type === 'NEW_PUSH_MESSAGE') {
              this.$store.dispatch('setPushLastMessage', event.data);
            } else if (event.data.type === 'NEW_PUSH_CLICK') {
              if (this.$auth.loggedIn) {
                if (location.pathname !== 'event.data.data.checkUrl') {
                  this.$router.push(event.data.data.checkUrl);
                }
              } else {
                location.href = event.data.data.openUrl;
              }
            }
          };

          if (window.Notification.permission === 'denied') {
            this.$store.dispatch('setPushSubscribed', 'denied');
          } else {
            registration.pushManager
              .getSubscription()
              .then((subscription) => {
                this.$store.dispatch(
                  'setPushSubscribed',
                  subscription ? JSON.stringify(subscription) : false
                );
                if (this.$auth.isLoggedIn) {
                  this.addPushSubscriptionToAccount();
                }
              })
              .catch(() => {
                // err
                this.$store.dispatch('setPushSubscribed', false);
              });
          }
        } catch (e) {
          // err
        }
      }
    },
    openSignup() {
      if (this.$auth.loggedIn) {
        this.$router.push(
          this.localePath({
            name: 'members',
          })
        );
      } else if (this.$ua.isFromSmartphone()) {
        this.$router.push(
          this.localePath({
            name: 'signup',
          })
        );
      } else {
        this.$bvModal.show('signup-modal');
      }
    },
    storeTrackingQuery() {
      if (this.$route.query.network || this.$route.query.email) {
        const query = Object.assign({}, this.$route.query);

        if (this.$route.query.network) {
          const trackingParameters = {
            network: query.network,
            ref: (query.ref || '').replace('{', '').replace('}', ''),
            sub1: (
              query.sub ||
              query.sub1 ||
              query.aff_sub ||
              query.aff_sub1 ||
              ''
            )
              .replace('{', '')
              .replace('}', ''),
            sub2: (query.sub2 || query.aff_sub2 || '')
              .replace('{', '')
              .replace('}', ''),
            sub3: (query.sub3 || query.aff_sub3 || '')
              .replace('{', '')
              .replace('}', ''),
          };

          this.$auth.$storage.setUniversal('network', trackingParameters);
        }

        delete query.network;
        delete query.ref;
        delete query.sub;
        delete query.sub1;
        delete query.sub2;
        delete query.sub3;
        delete query.aff_sub;
        delete query.aff_sub1;
        delete query.aff_sub2;
        delete query.aff_sub3;
        delete query.cpc;
        this.$router.replace({ query });
      }
    },

    countryName(countryCode) {
      if (Intl && Intl?.DisplayNames && Intl?.DisplayNames !== null) {
        const displayNames = new Intl.DisplayNames(this.$i18n.locale, {
          type: 'region',
        });
        try {
          return displayNames.of(countryCode);
        } catch (e) {}
      }
      return countryCode;
    },
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    displayFictionalIcon() {
      if (this.$auth.user) {
        return this.$auth.user.fictional_icon;
      }
      return this.$store.state.settings.fictional_icon;
    },

    designVersion() {
      return process.env.designVersion || 1;
    },
    censoredCDNPrefix() {
      return process.env.NODE_ENV === 'development'
        ? 'http://local.api.buca.media/cdn/'
        : '/cdn/';
    },

    hasTrafficsource() {
      return (
        (!!this.$auth.$storage.getUniversal('network') && this.isMounted) ||
        !!this.$route.query.network
      );
    },
    signupsEnabled() {
      return (
        this.$store.state.settings.signups === 'all' ||
        (this.$store.state.settings.signups === 'with_trafficsource' &&
          this.hasTrafficsource)
      );
    },
    indexSearchVisible() {
      return (
        this.$auth.loggedIn ||
        this.$store.state.settings.logged_out_search !== 'none' ||
        (this.$store.state.settings.logged_out_search_trafficsource !==
          'none' &&
          this.hasTrafficsource)
      );
    },
    hasPushSubscription() {
      return (
        this.$store.state.push.isSubscribed &&
        this.$store.state.push.isSubscribed !== 'denied'
      );
    },
    pushDenied() {
      return (
        this.$store.state.push.isSubscribed &&
        this.$store.state.push.isSubscribed === 'denied'
      );
    },
    pushSupported() {
      return this.$store.state.push.isCapable;
    },
    displayPushOption() {
      if (this.$store.state.push.isCapable === null) {
        return null;
      }
      if (
        this.$store.state.push.isCapable &&
        this.$store.state.push.isSubscribed === null
      ) {
        return null;
      }
      return (
        this.$auth.loggedIn &&
        this.$store.state.push.isCapable &&
        this.$store.state.push.isSubscribed === false &&
        this.$store.state.settings.push_public_key
      );
    },
    lastPushedMessage() {
      return this.$store.state.push.lastMessage;
    },
    formEmail: {
      get() {
        return this.$store.state.form.email;
      },
      set(value) {
        this.$store.dispatch('updateFormField', {
          field: 'email',
          value,
        });
      },
    },
    formName: {
      get() {
        return this.$store.state.form.name;
      },
      set(value) {
        this.$store.dispatch('updateFormField', {
          field: 'name',
          value,
        });
      },
    },
    formPassword: {
      get() {
        return this.$store.state.form.password;
      },
      set(value) {
        this.$store.dispatch('updateFormField', {
          field: 'password',
          value,
        });
      },
    },

    formDateOfBirth: {
      get() {
        return this.$store.state.form.date_of_birth;
      },
      set(value) {
        this.$store.dispatch('updateFormField', {
          field: 'date_of_birth',
          value,
        });
      },
    },

    formSignupMessage: {
      get() {
        return this.$store.state.form.signup_message;
      },
      set(value) {
        this.$store.dispatch('updateFormField', {
          field: 'signup_message',
          value,
        });
      },
    },

    formattedMinimumMessageCosts() {
      const country =
        this.$auth.loggedIn && this.$auth.user
          ? this.$auth.user.profile.country
          : this.$store.state.settings.geoip_selected_country;

      const backupCountry = this.$store.state.settings.geoip_selected_country;
      const config =
        this.$store.state.settings.minimum_message_costs[country] ||
        this.$store.state.settings.minimum_message_costs[backupCountry];

      return parseFloat(config.costs).toLocaleString(undefined, {
        style: 'currency',
        currency: config.currency,
      });
    },
  },
};
if (!Vue.__twizie_mixin__) {
  Vue.__twizie_mixin__ = true;
  Vue.mixin(mixins);
}
