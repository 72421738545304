import { render, staticRenderFns } from "./chat.vue?vue&type=template&id=2c6c3aa8"
import script from "./chat.vue?vue&type=script&lang=js"
export * from "./chat.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EntryWarning: require('/home/code/flirt-frontends/packages/twizie/components/EntryWarning.vue').default})
