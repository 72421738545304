export const state = () => ({
  gender: null,
  country: null,
  countryregion: null,
  min_age: null,
  max_age: null,
  didInit: false,
  results: [],
  numResults: null,
  otherCachedProfiles: [],
  similar_results: [],
  similar_numResults: null,
});

export const mutations = {
  set_gender(state, gender) {
    state.gender = gender;
  },
  set_country(state, country) {
    state.country = country;
  },
  set_countryregion(state, region) {
    state.countryregion = region;
  },
  set_min_age(state, value) {
    state.min_age = value;
  },
  set_max_age(state, value) {
    state.max_age = value;
  },
  set_num_results(state, value) {
    state.numResults = value;
  },
  set_did_init(state, value) {
    state.didInit = value;
  },
  add_other_profile(state, value) {
    state.otherCachedProfiles.push(value);
  },

  set_results(state, { results, pageNumber }) {
    state.results.push({
      pageNumber,
      results,
    });
  },
  clear_results(state) {
    state.results = [];
    state.numResults = null;
  },
  set_similar_num_results(state, value) {
    state.similar_numResults = value;
  },
  set_similar_results(state, { results, pageNumber }) {
    state.similar_results.push({
      pageNumber,
      results,
    });
  },
  clear_similar_results(state) {
    state.similar_results = [];
    state.similar_numResults = null;
  },
};
