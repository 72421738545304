
export default {
  name: 'Default',
  data() {
    return {
      isPWA: false,

      likeVisible: false,
      fetchUserInterval: null,
      needsProfileEnrichAlert: false,
      needsPushNotificationAlert: false,
      needsDisabledNotificationsAlert: false,
      signupInProgress: false,
    };
  },
  head() {
    let splashScreens = [];
    if (
      this.$ua.isFromIphone() ||
      this.$ua.isFromIpod() ||
      this.$ua.isFromIpad()
    ) {
      splashScreens = [
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png',
          media:
            'screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png',
          media:
            'screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png',
          media:
            'screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_landscape.png',
          media:
            'screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/10.2__iPad_portrait.png',
          media:
            'screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_portrait.png',
          media:
            'screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png',
          media:
            'screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png',
          media:
            'screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_11__iPhone_XR_portrait.png',
          media:
            'screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_landscape.png',
          media:
            'screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/10.2__iPad_landscape.png',
          media:
            'screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png',
          media:
            'screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png',
          media:
            'screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png',
          media:
            'screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png',
          media:
            'screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png',
          media:
            'screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/8.3__iPad_Mini_portrait.png',
          media:
            'screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png',
          media:
            'screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/10.9__iPad_Air_portrait.png',
          media:
            'screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png',
          media:
            'screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/10.9__iPad_Air_landscape.png',
          media:
            'screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/11__iPad_Pro__10.5__iPad_Pro_landscape.png',
          media:
            'screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_portrait.png',
          media:
            'screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png',
          media:
            'screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png',
          media:
            'screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/12.9__iPad_Pro_landscape.png',
          media:
            'screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/10.5__iPad_Air_landscape.png',
          media:
            'screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/10.5__iPad_Air_portrait.png',
          media:
            'screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png',
          media:
            'screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/iPhone_11__iPhone_XR_landscape.png',
          media:
            'screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/12.9__iPad_Pro_portrait.png',
          media:
            'screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/11__iPad_Pro__10.5__iPad_Pro_portrait.png',
          media:
            'screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png',
          media:
            'screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/splash_screens/8.3__iPad_Mini_landscape.png',
          media:
            'screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)',
        },
      ];
    }
    return {
      title: this.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.settings.description,
        },
      ],
      link: splashScreens,
    };
  },
  computed: {
    title() {
      if (
        this.$store.getters['chat/getTotalUnreadMessages'] ||
        this.$store.getters['chat/getTotalNewLikes']
      ) {
        const totalNewItems =
          (this.$store.getters['chat/getTotalUnreadMessages'] || 0) +
          (this.$store.getters['chat/getTotalNewLikes'] || 0);

        return (
          '(' + totalNewItems + ') ' + this.$store.state.settings.index_title
        );
      }
      return this.$store.state.settings.index_title;
    },
  },
  watch: {
    lastPushedMessage(newValue) {
      // reload chats
      this.$store.dispatch('chat/load_chats');
      this.$store.dispatch('chat/load_likes');
    },
  },
  mounted() {
    this.isPWA = window.matchMedia('(display-mode: standalone)')?.matches;

    if (this.$route.query.t && parseInt(this.$route.query.t)) {
      setTimeout(() => {
        this.likeVisible = true;
      }, parseInt(this.$route.query.t) * 1000);

      const query = Object.assign({}, this.$route.query);
      delete query.t;
      this.$router.replace({ query });
    }

    this.detectPushCapabilities();
    if (this.$route.query.lb) {
      this.enablePageBounceLogging();
    }

    setTimeout(() => {
      if (!this.fetchUserInterval) {
        this.storeTrackingQuery();

        // use a higher timeout when push is enabled
        const refreshTimout = this.$store.state.push.isSubscribed
          ? 300000
          : 60000;

        this.fetchUserInterval = setInterval(() => {
          if (this.$auth.loggedIn) {
            this.$auth.fetchUser();
            this.$store.dispatch('chat/load_chats');
            this.$store.dispatch('chat/load_likes');
          }
        }, refreshTimout);
      }
    }, 5000);
    if (
      this.$auth.loggedIn &&
      (this.$store.state.chat.chats === null ||
        this.$store.state.chat.likes === null)
    ) {
      this.$store.dispatch('chat/load_chats');
      this.$store.dispatch('chat/load_likes');
    }

    if (!this.$auth.$storage.getUniversal('hide-enrich-alert')) {
      this.needsProfileEnrichAlert = true;
    }
    if (!this.$auth.$storage.getUniversal('hide-push-notification-alert')) {
      this.needsPushNotificationAlert = true;
    }
    if (
      !this.$auth.$storage.getUniversal('hide-disabled-notifications-alert')
    ) {
      this.needsDisabledNotificationsAlert = true;
    }
  },
  beforeDestroy() {
    if (this.fetchUserInterval) {
      clearInterval(this.fetchUserInterval);
    }
  },
  methods: {
    likeProfilePressed(profileId) {
      this.$router.push({
        name: 'members-id',
        params: {
          id: profileId,
        },
      });
      this.likeVisible = false;
    },
    dismissDisabledNotificationsAlert() {
      this.$auth.$storage.setUniversal(
        'hide-disabled-notifications-alert',
        this.$dayjs().unix()
      );
    },

    dismissEnrichProfileAlert() {
      this.$auth.$storage.setUniversal(
        'hide-enrich-alert',
        this.$dayjs().unix()
      );
    },
    dismissPushNotificationAlert() {
      this.$auth.$storage.setUniversal(
        'hide-push-notification-alert',
        this.$dayjs().unix()
      );
    },
  },
};
